import React from "react";
import Header from "../components/header/header";

import Star from "../assets/img/star.png";
import LittleBall from "../assets/img/pinkball.png";
import Cube from "../assets/img/cube.png";
import Pyramid from "../assets/img/pyramid.png";
import Brush from "../assets/img/svg/plugIcon/brush.svg";
import Devices from "../assets/img/svg/plugIcon/devices.svg";
import Draw from "../assets/img/svg/plugIcon/draw.svg";
import Glass from "../assets/img/svg/plugIcon/glass.svg";
import LinkBold from "../assets/img/svg/plugIcon/link-bold.svg";
import Pantone from "../assets/img/svg/plugIcon/pantone.svg";
import Path from "../assets/img/svg/plugIcon/path.svg";
import Pencil from "../assets/img/svg/plugIcon/pencil.svg";
import QrCode from "../assets/img/svg/plugIcon/qrcode.svg";
import Translate from "../assets/img/svg/plugIcon/translate.svg";
import BackEnd from "../assets/img/backend.png";

import "../assets/styles/pages/_functionalities.scss";

const PageProps = {
  title: "Funzionalità",
  tagline: "Funzionalità",
  metaDescription: "",
  banner: "laptop.png",
  alt: "Laptop",
};

const FRONTEND = [
  {
    image: Draw,
    title: `Comunicazione automatizzata con il cliente`,
    body: `Quesiti mirati a risolvere le eventuali problematiche riscontrate dal cliente mentre soggiorna/consuma nella tua struttura, così potrai monitorare la situazione. `,
  },
  {
    image: Brush,
    title: `Grafica intuitiva`,
    body: `Back office ben strutturato e di semplice utilizzo.`,
  },
  {
    image: Devices,
    title: `Responsive Design`,
    body: `Permette di essere correttamente visualizzato da qualsiasi device (PC, smartphone o tablet) ed è supportato da sistemi Android, IOS.`,
  },
  {
    image: Translate,
    title: `Sistema multilingue`,
    body: `Coccola il tuo cliente e fallo sentire subito a casa sua, grazie al nostro servizio di autenticazione che gli consentirà di visualizzare direttamente la landing page nella lingua impostata sul suo device.`,
  },
  {
    image: Pantone,
    title: `Personalizzazione ed affidabilità`,
    body: `Rivolgiti direttamente al cliente con aumento della fidelizzazione. Il software contatta direttamente il tuo cliente con l’invio di SMS o Email. La facilità di interazione e l’offerta del multi-lingua, agisce da moltiplicatore per l’utilizzo del servizio, garantendo una base di recensioni positive in costante crescita, ed una gestione di quelle negative, risolvendo direttamente l’insoddisfazione del cliente.
    `,
  },
  {
    image: Pencil,
    title: `Customer Experience`,
    body: `Questionari personalizzabili: il cliente impiegherà pochi minuti per la compilazione.`,
  },
];

const BACKEND = [
  {
    image: QrCode,
    title: `Fast Review con QRCode in loco “Scan me for a fast review”`,
    body: `Quesiti mirati a risolvere le eventuali problematiche riscontrate dal cliente mentre soggiorna/ consuma nella tua struttura, così potrai monitorare la situazione sui canali collegati (Facebook, Google, TripAdvisor, etc.). Puoi decidere a quale questionario far accedere l’utente: during stay e post stay.`,
  },
  {
    image: LinkBold,
    title: `Fast review manuale con email e/o sms post stay da backoffice`,
    body: `Da back office è possibile inserire nome e cognome, telefono ed email, per poter inviare istantaneamente il link per la compilazione della survey. Il messaggio sarà personalizzato per ogni singolo utente. Per l'invio degli SMS è necessario acquistare un pacchetto prepagato di crediti, con la possibilità di personalizzare il mittente del messaggio.`,
  },
  {
    image: Path,
    title: `Report periodico con statistiche da Back Office`,
    body: `Da backoffice è possibile impostare l'invio periodico di un report di statistiche. Nel report saranno indicate tutte le survey inviate, quelle in sospeso e quelle compilate ed il tasso di conversione.`,
  },
  {
    image: Glass,
    title: `Monitorizzazione e recensioni facebook, google e booking integrate da back office`,
    body: `Eucleia all'interno del backoffice ti offre l’opportunità di leggere le recensioni che gli utenti hanno rilasciato in rete sui canali Facebook e Google.it o market place di settore come Trip Advisor. Inoltre se la tua attività è una struttura ricettiva potrai leggere le recensioni Booking.com. Tutte recensioni che potrai condividere sul tuo sito o nei tuoi canali di digital communication. Integrare le recensioni sul tuo sito contribuirà a migliorare la fiducia, aumentare il tasso di conversione e le prenotazioni dirette. Più recensioni ti aiuteranno a scalare le classifiche e ad ottenere più clic e prenotazioni.`,
  },
];

const Functionalities = () => {
  return (
    <>
      <Header {...PageProps} />
      <div className="functionalities">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="pink">Front End</h2>
            </div>
          </div>
          {FRONTEND.map((i, index) => {
            let j = index !== null ? FRONTEND[index + 1] : null;
            if (index % 2 === 0) {
              return (
                <div className="columns" key={index}>
                  <div className="column is-5">
                    <div className="section" />
                    <img src={i.image} alt="" />
                    <br />
                    <br />
                    <span className="black bold">{i.title}</span>
                    <br />
                    <br />
                    <p>{i.body}</p>
                  </div>
                  {j ? (
                    <div className="column is-offset-2">
                      <div className="section" />
                      <img src={j.image} alt="" />
                      <br />
                      <br />
                      <span className="black bold">{j.title}</span>
                      <br />
                      <br />
                      <p>{j.body}</p>
                    </div>
                  ) : null}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
        <div className="section" />
        <div className="darkBlue">
          <div className="section" />
          <div className="container">
            <div className="columns">
              <div className="column is-6">
                <h2 className="green">Back End</h2>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                {BACKEND.map((i, index) => (
                  <div key={index}>
                    <div className="section" />
                    <img src={i.image} alt="" />
                    <br />
                    <br />
                    <h4>{i.title}</h4>
                    <br />
                    <br />
                    <p>{i.body}</p>
                  </div>
                ))}
              </div>
              <div className="column is-offset-2">
                <div className="container backend">
                  <img id="cube" src={Cube} alt={`Cube Icon`} />
                  <img id="littleBall" src={LittleBall} alt={`Ball Icon`} />
                  <img id="pyramid" src={Pyramid} alt={`Pyramid Icon`} />
                  <img id="star" src={Star} alt={`Star Icon`} />
                  <img src={BackEnd} alt="Back End Icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="section" />
        </div>
      </div>
    </>
  );
};

export default Functionalities;
